import { NavigationType } from '@scandipwa/scandipwa/src/store/Navigation/Navigation.type';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import DataContainer from 'Util/Request/DataContainer';

import HelpSwitcherComponent from './HelpSwitcher.component';

interface Props {
    changeHeaderState: (state: any) => void;
    device: any;
}

/** @namespace KemanaHelpSwitcher/Component/HelpSwitcher/Contrainer/mapStateToProps */
export const mapStateToProps = (state: any) => ({
    device: state.ConfigReducer.device,
});

/** @namespace KemanaHelpSwitcher/Component/HelpSwitcher/Contrainer/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeHeaderState: (state: any) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
});

/** @namespace KemanaHelpSwitcher/Component/HelpSwitcher/Contrainer */
export class HelpSwitcherContrainer extends DataContainer<Props> {
    static propTypes = {
        changeHeaderState: PropTypes.func.isRequired,
    };

    containerFunctions = {
        handleHelpSelect: this.handleHelpSelect.bind(this),
        onHelpOutsideClick: this.onHelpOutsideClick.bind(this),
    };

    state={
        isSelectedvalue: false,
    };

    onHelpOutsideClick() {
        const {
            device,
        } = this.props;

        if (device.isMobile) {
            return;
        }
        this.setState({ isSelectedvalue: false });
    }

    handleHelpSelect() {
        const { isSelectedvalue } = this.state;
        const { changeHeaderState } = this.props;
        const toggleValue = !isSelectedvalue;
        this.setState({ isSelectedvalue: toggleValue });
        changeHeaderState({
            name: 'menu_subcategory',
            force: true,
            title: 'Help',
            onBackClick: () => this.handleHelpSelectBack(),
        });
    }

    handleHelpSelectBack() {
        const { isSelectedvalue } = this.state;
        const { changeHeaderState } = this.props;
        const toggleValue = !isSelectedvalue;
        this.setState({ isSelectedvalue: toggleValue });
        changeHeaderState({
            name: 'menu',
            force: true,
            title: '',
        });
    }

    render() {
        return (
            <HelpSwitcherComponent
              { ...this.containerFunctions }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpSwitcherContrainer);
