/* eslint-disable react/forbid-elements */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Link from 'Component/Link';

import { HelpSwitcherProps } from './HelpSwitcher.type';

import './HelpSwitcher.style';

/** @namespace KemanaHelpSwitcher/Component/HelpSwitcher/Component */
export class HelpSwitcherComponent extends PureComponent<HelpSwitcherProps> {
    static propTypes:any = {
        handleHelpSelect: PropTypes.func.isRequired,
        isSelectedvalue: PropTypes.bool.isRequired,
    };

    renderHelpLiveChatLink() {
        return (
            <button
              block="HelpSwitcher"
              elem="LiveChat"
              mix={ { block: 'HelpSwitcher', elem: 'HelpItem' } }
              key="HelpItem"
            >
                <span
                  block="HelpSwitcher"
                  elem="SubTitle"
                >
                    { __('Live Chat') }
                </span>
                <span>{ __('Everyday, 11 AM - 10PM GMT+8') }</span>
            </button>
        );
    }

    renderHelpQLink() {
        return (
            <Link
              to="/contact"
              block="HelpSwitcher"
              elem="Contact"
              mix={ { block: 'HelpSwitcher', elem: 'HelpItem' } }
              key="HelpItem"
            >
                <span
                  block="HelpSwitcher"
                  elem="SubTitle"
                >
                    { __('Contact Form') }
                </span>
                <span>{ __('Monday - Friday, 8 AM - 10 PM GMT+8') }</span>
            </Link>
        );
    }

    renderHelpMailLink() {
        return (
            <div
              block="HelpSwitcher"
              elem="Call"
              mix={ { block: 'HelpSwitcher', elem: 'HelpItem' } }
              key="HelpItem"
            >
                <span
                  block="HelpSwitcher"
                  elem="SubTitle"
                >
                    <a href="Tel:+65811-129-3838">{ __('+65 811-129-3838') }</a>
                </span>
                <span>{ __('Monday - Friday, 9 AM - 4 PM GMT+8') }</span>
            </div>
        );
    }

    render() {
        const {
            handleHelpSelect,
            isSelectedvalue,
        } = this.props;

        const helpDropItems = [
            { id: 1, label: this.renderHelpLiveChatLink(), value: 'livechat' },
            { id: 2, label: this.renderHelpQLink(), value: 'faqs' },
            { id: 3, label: this.renderHelpMailLink(), value: 'support' },
        ];

        return (

            <ClickOutside>
            <button
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleHelpSelect() }
              block="HelpSwitcher"
            >
                <div block="HelpSwitcher" elem="Title" mods={ { isActive: isSelectedvalue } }>
                    <span>{ __('Help') }</span>
                </div>
                <div block="HelpSwitcher" elem="HelpSwitcherList" mods={ { isActive: isSelectedvalue } }>
                    <ul>
                    { helpDropItems?.map(({ id, label }) => (
                        <li
                          key={ id }
                          block="HelpSwitcher"
                          elem="Values"
                        >
                            { label }
                        </li>
                    )) }
                    </ul>
                </div>
            </button>
            </ClickOutside>
        );
    }
}

export default HelpSwitcherComponent;
