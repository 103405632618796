import getStore from 'Util/Store';

import HelpSwitcher from '../component/HelpSwitcher';

const store = getStore();

const renderComparePageButton = (args:any, callback:() => void, instance:any) => {
    const { device: { isMobile } } = instance.props;

    if (isMobile) {
        return null;
    }

    return (
        <>
   { callback.apply(instance, args) }
    <div block="Header" elem="HelpSwitcher">
      <HelpSwitcher store={ store } />
    </div>
        </>
    );
};

const renderAdditionalInformation = (args:any, callback:() => void, instance:any) => {
    const { device: { isMobile } } = instance.props;

    if (!isMobile){
        return null;
    }

    return (
        <>
        <div block="Menu" elem="CompareLinkWrapper">
                <div block="Menu" elem="CompareLink">
                    <HelpSwitcher store={ store } />
                </div>
        </div>
 { callback.apply(instance, args) }

        </>
    );
};

export default {
    'Component/Header/Component': {
        'member-function': {
            renderComparePageButton,
        },
    },
    'Component/Menu/Component': {
        'member-function': {
            renderAdditionalInformation,
        },
    },
};
